import React from "react";
import styles from "./Footer.module.css";
import LogoFooter from "../../images/logoAlpha_Footer.png";
import { NavLink } from "react-router-dom";

const Footer = ({ onHandlePages, lang }) => {
  return (
    <main className={styles.footer}>
      <section className={styles.footerSection}>
        <div className={styles.logoDiv}>
          <img id={styles.logoFooter} src={LogoFooter} alt="Logo Hemap" />
        </div>
        <div className={styles.containerLinks}>
          <NavLink to={`/produtos`} onClick={() => onHandlePages("Produtos")}>
            <h5 id={styles.linksTitle}>{lang === "BRA" ? "NOSSOS PRODUTOS" : "OUR PRODUCTS"}</h5>
          </NavLink>
          <ul id={styles.linkList}>
            <NavLink
              to={`/produtos/dossie-conitec-e-ans`}
              onClick={() => onHandlePages("Produtos")}
            >
              <li id={styles.link}>{lang === "BRA" ? "Dossiê Conitec e ANS" : "ANS and CONITEC dossiers"}</li>
            </NavLink>
            <NavLink
              to={`/produtos/dossie-cmed`}
              onClick={() => onHandlePages("Produtos")}
            >
              <li id={styles.link}>{lang === "BRA" ? "Dossiê CMED" : "CMED dossier"}</li>
            </NavLink>
            <NavLink
              to={`/produtos/analise-critica-pre-submissao`}
              onClick={() => onHandlePages("Produtos")}
            >
              <li id={styles.link}>{lang === "BRA" ? "Análise crítica pré submissão" : "Pre-Submission Critical Analysis"}</li>
            </NavLink>
            <NavLink
              to={`/produtos/estudo-de-custo-efetividade`}
              onClick={() => onHandlePages("Produtos")}
            >
              <li id={styles.link}>{lang === "BRA" ? "Estudos de custo-efetividade" : "Cost-Effectiveness Study"}</li>
            </NavLink>
            <NavLink to={`/produtos`} onClick={() => onHandlePages("Produtos")}>
              <li id={styles.link}>{lang === "BRA" ? "Ver mais..." : "See more..."}</li>
            </NavLink>
          </ul>
        </div>
        <div className={styles.containerLinks}>
          <h5 id={styles.linksTitle}>LINKS</h5>
          <ul id={styles.linkList}>
            <NavLink to={`/equipe`} onClick={() => onHandlePages("Equipe")}>
              <li id={styles.link}>{lang === "BRA" ? "Equipe" : "Team"}</li>
            </NavLink>
            <NavLink to={`/clientes`} onClick={() => onHandlePages("Clientes")}>
              <li id={styles.link}>{lang === "BRA" ? "Clientes" : "Clients"}</li>
            </NavLink>
            <NavLink
              to={`/equipe/dr-rodrigo-antonini-ribeiro`}
              onClick={() => onHandlePages("Equipe")}
            >
              <li id={styles.link}>{lang === "BRA" ? "Nosso CEO" : "Our CEO"}</li>
            </NavLink>
            <NavLink to={`/sobre`} onClick={() => onHandlePages("Sobre")}>
              <li id={styles.link}>{lang === "BRA" ? "Sobre" : "About"}</li>
            </NavLink>
            <NavLink to={`/contato`} onClick={() => onHandlePages("Contato")}>
              <li id={styles.link}>{lang === "BRA" ? "Contato" : "Contact"}</li>
            </NavLink>
          </ul>
        </div>
        <div className={styles.containerLinks}>
          <h5 id={styles.linksTitle}>{lang === "BRA" ? "CONTATO" : "CONTACT"}</h5>
          <ul id={styles.linkList}>
            <li id={styles.link}>{lang === "BRA" ? "Av. General Flores da Cunha," : "Contact"}</li>
            <li id={styles.link}>{lang === "BRA" ? "Número 1050 - Sala 704." : "Number 1050 - Room 704."}</li>
            <li id={styles.link}>{lang === "BRA" ? "Cachoeirinha/RS - Brasil." : "Cachoeirinha/RS - Brazil."}</li>
            <li id={styles.link}>{lang === "BRA" ? "contato@hemap.com.br" : "contato@hemap.com.br"}</li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Footer;
