import React, { useState } from "react";
import styles from "./Contato.module.css";
import emailjs from "emailjs-com";
import Button from "../../components/Button/Button";
import IconBtnSaibaMais from "../../images/iconBtn1.svg";

const Contato = ({ lang }) => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mensagem.length > 300) {
      alert("Mensagem muito longa. Máximo de 300 caracteres.");
    } else {
      const templateParams = {
        to_name: "Rodrigo Ribeiro",
        from_name: nome,
        from_email: email,
        message: mensagem,
      };

      emailjs
        .send(
          "service_lqffv7q",
          "template_at9l85n",
          templateParams,
          "IhuoSlL7ckp3jcGZq"
        )
        .then(
          (response) => {
            console.log("Mensagem enviada com sucesso!", response);
            setNome("");
            setEmail("");
            setMensagem("");
            alert("Mensagem enviada com sucesso!");
          },
          (error) => {
            console.log("Falha ao enviar mensagem:", error);
            alert("Falha ao enviar mensagem. Tente novamente.");
          }
        );
    }
  };

  return (
    <main className={styles.contatoPage}>
      <div className={styles.contatoDiv}>
        <form className={styles.formContato} onSubmit={handleSubmit}>
          <div className={styles.pessoaInfo}>
            <input
              id={styles.input}
              maxlength="60"
              type="text"
              placeholder={lang === "BRA" ? "Nome" : "Name"}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              id={styles.input}
              maxlength="60"
              type="email"
              placeholder={lang === "BRA" ? "Email" : "Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <textarea
            id={styles.textArea}
            maxlength="300"
            placeholder={
              lang === "BRA"
                ? "Mensagem (limite de 300 caracteres)"
                : "Leave your message (limited to 300 characters)"
            }
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
        </form>
        <Button
          text={lang === "BRA" ? "ENVIAR" : "SEND"}
          icon={IconBtnSaibaMais}
          onClick={handleSubmit}
        />
      </div>
    </main>
  );
};

export default Contato;
