import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Sobre from "./pages/Sobre/Sobre";
import Equipe from "./pages/Equipe/Equipe";
import Produtos from "./pages/Produtos/Produtos";
import Clientes from "./pages/Clientes/Clientes";
import Contato from "./pages/Contato/Contato";
import Blog from "./pages/Blog/Blog";
import Produto01 from "./pages/Produtos/Produtos01";
import Produto02 from "./pages/Produtos/Produtos02";
import Produto03 from "./pages/Produtos/Produtos03";
import Produto04 from "./pages/Produtos/Produtos04";
import Produto05 from "./pages/Produtos/Produtos05";
import Produto06 from "./pages/Produtos/Produtos06";
import Produto07 from "./pages/Produtos/Produtos07";
import Produto08 from "./pages/Produtos/Produtos08";
import Produto09 from "./pages/Produtos/Produtos09";
import Produto10 from "./pages/Produtos/Produtos10";
import Produto11 from "./pages/Produtos/Produtos11";
import Colaborador01 from "./pages/Colaborador/Colaborador01";
import Colaborador02 from "./pages/Colaborador/Colaborador02";
import Colaborador03 from "./pages/Colaborador/Colaborador03";
import Colaborador04 from "./pages/Colaborador/Colaborador04";
import Colaborador05 from "./pages/Colaborador/Colaborador05";
import Colaborador06 from "./pages/Colaborador/Colaborador06";
import Colaborador07 from "./pages/Colaborador/Colaborador07";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";

const AppRoutes = ({ page, onHandlePages, lang }) => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const pageNew = currentPath.split("/")[1];

    const currentPage =
      pageNew === ""
        ? "Home"
        : pageNew.charAt(0).toUpperCase() + pageNew.slice(1).toLowerCase();

    onHandlePages(currentPage);

    console.log("renderizei no useEffect");
  }, [onHandlePages]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Home page={page} onHandlePages={onHandlePages} lang={lang} />
          }
        />
        <Route
          path="/sobre"
          element={<Sobre onHandlePages={onHandlePages} lang={lang} />}
        />

        <Route
          path="/produtos"
          element={<Produtos onHandlePages={onHandlePages} lang={lang} />}
        />
        <Route
          path="/produtos/dossie-conitec-e-ans"
          element={<Produto01 lang={lang} />}
        />
        <Route
          path="/produtos/dossie-cmed"
          element={<Produto02 lang={lang} />}
        />
        <Route
          path="/produtos/pre-assessments-de-tecnologias-em-saude"
          element={<Produto03 lang={lang} />}
        />
        <Route
          path="/produtos/estudo-de-custo-efetividade"
          element={<Produto04 lang={lang} />}
        />
        <Route
          path="/produtos/analise-de-impacto-orcamentario"
          element={<Produto05 lang={lang} />}
        />
        <Route
          path="/produtos/estudos-de-revisao-sistematica"
          element={<Produto06 lang={lang} />}
        />
        <Route
          path="/produtos/metanalises-em-rede"
          element={<Produto07 lang={lang} />}
        />
        <Route
          path="/produtos/moderacao-de-painel-de-especialistas"
          element={<Produto08 lang={lang} />}
        />
        <Route
          path="/produtos/escrita-cientifica-abstract-e-artigos"
          element={<Produto09 lang={lang} />}
        />
        <Route
          path="/produtos/calculadora-de-custos-de-tratamento"
          element={<Produto10 lang={lang} />}
        />
        <Route
          path="/produtos/analise-critica-pre-submissao"
          element={<Produto11 lang={lang} />}
        />

        <Route
          path="/equipe"
          element={<Equipe onHandlePages={onHandlePages} lang={lang} />}
        />
        <Route
          path="/equipe/dr-rodrigo-antonini-ribeiro"
          element={<Colaborador01 lang={lang} />}
        />
        <Route
          path="/equipe/dr-eduardo-bertoli"
          element={<Colaborador02 lang={lang} />}
        />
        <Route
          path="/equipe/dr-leo-sekine"
          element={<Colaborador03 lang={lang} />}
        />

        <Route
          path="/equipe/dr-jc-salomao"
          element={<Colaborador04 lang={lang} />}
        />
        <Route
          path="/equipe/dra-luciane-maria-restelatto"
          element={<Colaborador05 lang={lang} />}
        />

        <Route
          path="/equipe/miriam-zago-marcolino"
          element={<Colaborador06 lang={lang} />}
        />
        <Route
          path="/equipe/karlyse-claudino-belli"
          element={<Colaborador07 lang={lang} />}
        />

        <Route
          path="/clientes"
          element={<Clientes page={page} lang={lang} />}
        />
        <Route path="/contato" element={<Contato lang={lang} />} />
        <Route path="/blog" element={<Blog lang={lang} />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
